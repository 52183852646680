<template>
  <div>
    <van-cell-group title="填写借用单据详情">
      <van-cell v-for="(it, idx) in grid" :key="idx" :title="it.ST_NAME" v-model="it.AMT" @click="itemEdit(it, idx)">
        <template #default>{{ it.CNT }} ({{ it.ST_UNIT }})</template>
        <template #label>
          <span v-if="it.DESC"> | {{ it.DESC }}</span>
        </template>
      </van-cell>
    </van-cell-group>
    <div style="margin: 15px">
      <van-button type="info" size="large" icon="plus" block plain @click="itemEdit()">增加物品</van-button>
      <br />
      <van-button type="primary" size="large" icon="success" block @click="submit" :loading="ret.loading"
        loading-text="正在提交。。。">确认提交</van-button>
      <br />
      <van-button plain type="default" size="large" icon="cross" block to="/mat/valid">取消返回</van-button>
    </div>
    <van-popup v-model="ipt.show" position="bottom" round>
      <van-field readonly label="分类" placeholder="请选择分类" is-link @click="ipt.cat.show = true" v-model="item.CAT_NAME" />
      <van-popup v-model="ipt.cat.show" round position="bottom">
        <van-cascader title="请选择分类" :options="ipt.cat.ls" @finish="catSel" @close="ipt.cat.show = false"
          v-model="item.CAT_ID" :field-names="{ text: 'NAME', value: 'ID', children: 'CHILDREN' }" />
      </van-popup>
      <van-field readonly label="物品" placeholder="请选择物品" is-link @click="onStockClick" v-model="item.ST_NAME"
        required />
      <van-popup v-model="ipt.stock.show" round position="bottom">
        <van-search @search="onStockSearch" v-model="ipt.stock.key" show-action>
          <template #action>
            <van-button type="info" size="small" style="vertical-align: middle" @click="onStockSearch">搜索</van-button>
          </template>
        </van-search>
        <van-picker :columns="ipt.stock.lss" show-toolbar @cancel="ipt.stock.show = false" @confirm="stockChange">
          <template slot-scope="scope" slot="option">
            <van-cell :value="scope.BALANCE">
              <template #title>
                {{ scope.NAME }} 【{{ scope.FUNIT }}】
              </template>
            </van-cell>
          </template>
        </van-picker>
      </van-popup>
      <van-field v-model="item.CNT" type="digit" label="数量" placeholder="请输入数量" required />
      <div style="padding: 20px; text-align: center">
        <van-button type="info" size="normal" icon="success" plain @click="itemOk">完 成</van-button>
        <van-button v-if="ipt.idx >= 0" type="warning" size="normal" icon="close" plain @click="itemDel"
          style="margin-left: 30px">删 除</van-button>
        <van-button v-else type="primary" size="normal" icon="share-o" plain @click="itemNext"
          style="margin-left: 30px">下一个</van-button>
      </div>
    </van-popup>
  </div>
</template>
<style scoped></style>
<script>
import { Toast } from 'vant';
export default {
  name: "new",
  data() {
    return {
      model: { AMT: 0 },
      item: {},
      grid: [],
      ret: {
        loading: false
      },
      ipt: {
        show: true,
        cat: {
          show: false,
          ls: [],
          txt: ""
        },
        stock: {
          show: false,
          ls: [],
          txt: "",
          lss: [],
          key: ""
        },
        idx: -1
      }
    }
  },
  created() {
    this.getCat();
  },
  methods: {
    getCat() {
      let self = this;
      this.whale.remote.getResult({
        url: "/api/Mobile/MAT/MCatApi/GetTree",
        completed: function (its) {
          self.ipt.cat.ls = its.DATA;
        }
      })
    },
    catSel({ selectedOptions }) {
      this.ipt.cat.show = false;
      this.item.CAT_NAME = selectedOptions.map((option) => option.NAME).join('/');
      this.getStock();
    },
    onStockClick() {
      this.ipt.stock.show = true;
      this.getStock();
    },
    getStock() {
      let self = this;
      this.whale.remote.getCollection({
        url: "/api/Mobile/MAT/MStockApi/GetList",
        data: { CAT_ID: this.item.CAT_ID },
        completed: function (its) {
          console.log('stork', its)
          self.ipt.stock.ls = its.ITEMS;
          self.ipt.stock.lss = its.ITEMS;

          self.item.ST_ID = 0;
          self.item.ST_NAME = "";
        }
      })
    },
    stockChange(o) {
      this.ipt.stock.show = false;
      this.ipt.stock.txt = o.NAME;
      this.item.ST_ID = o.ID;
      this.item.ST_NAME = o.NAME;
      this.item.ST_UNIT = o.FUNIT;
      this.item.ST_PRICE = o.PRICE;
      this.item.CAT_NAME = o.CAT_NAME;
      this.item.CAT_ID = o.CAT_ID;
      this.item.BALANCE = o.BALANCE;
    },
    onStockSearch() {
      this.ipt.stock.lss = this.ipt.stock.ls.filter(o => {
        return o.NAME.indexOf(this.ipt.stock.key) >= 0
      })
      let self = this;
      this.whale.remote.getCollection({
        url: "/api/Mobile/MAT/MStockApi/GetList",
        data: { KEY: this.ipt.stock.key },
        completed: function (its) {
          // self.model.ST_ID = "";
          self.ipt.stock.ls = its.ITEMS;
          self.ipt.stock.lss = its.ITEMS;
        }
      })
    },
    itemEdit(o, i) {
      if (o == undefined) {
        this.item = {};
        this.ipt.cat.txt = "";
        this.ipt.idx = -1;
      } else {
        this.item = Object.assign({}, o);
        this.ipt.cat.txt = o.CAT_NAME;
        this.ipt.idx = i;
      }
      this.ipt.show = true;
    },
    itemDel() {
      this.grid = this.grid.filter((x, i) => {
        if (i != this.ipt.idx) return true;
        else {
          this.model.AMT -= x.AMT;
          return false;
        }
      });
      this.ipt.show = false;
    },
    itemOk() {
      if (this.item.ST_ID == undefined) {
        this.ipt.show = false;
        return
      }
      if (this.item.ST_ID == 0) {
        Toast.fail("请输入物品名称");
        return
      }
      if (this.item.CNT == undefined || parseFloat(this.item.CNT) <= 0) {
        Toast.fail("请输入数量");
        return;
      }
      this.item.AMT = this.item.ST_PRICE * this.item.CNT;
      if (this.item.CNT > this.item.BALANCE) {
        Toast.fail(`${this.item.ST_NAME} 库存不足， (库存：${this.item.BALANCE} ${this.item.ST_UNIT})。`)
        return
      }

      if (this.ipt.idx >= 0) {
        this.grid.map((o, i) => {
          if (i == this.ipt.idx) {
            this.model.AMT -= o.AMT;
            Object.assign(o, this.item);
          }
        })
      } else {
        this.grid.push(Object.assign({}, this.item));
      }
      this.model.AMT += this.item.AMT;
      this.ipt.show = false;
    },
    itemNext() {
      if (this.item.ST_ID == undefined) {
        Toast.fail("请选择物品")
        return
      }
      if (this.item.CNT == undefined) {
        Toast.fail("请输入数量")
        return
      }
      if (this.item.CNT > this.item.BALANCE) {
        Toast.fail(`${this.item.ST_NAME} 库存不足， (库存：${this.item.BALANCE} ${this.item.ST_UNIT})。`)
        return
      }
      this.item.AMT = this.item.ST_PRICE * this.item.CNT;
      this.grid.push(Object.assign({}, this.item));
      this.model.AMT += this.item.AMT;

      this.item.CNT = undefined;
      this.item.AMT = undefined;
      this.item.ST_ID = undefined;
      this.item.ST_NAME = '';
    },
    submit() {
      let self = this;
      if (this.grid.length == 0) {
        Toast.fail("没有物品")
        return
      }
      if (this.grid.length > 5) {
        Toast.fail("对不起，一次最多只能提交5条记录")
        return
      }
      let tt = [];
      this.grid.map(x => { tt.push(x.ST_NAME); })
      this.model.TITLE = tt.join();
      this.model.GBODY = JSON.stringify(this.grid);
      self.ret.loading = true;
      this.whale.remote.getResult({
        url: "/api/Mobile/RENT/RDatApi/Create",
        data: this.model,
        finally() { self.ret.loading = false; },
        completed() {
          self.$dialog.alert({
            title: "完成",
            message: "记录已成功提交！",
            theme: "round",
          }).then(() => { self.$router.push("/rent"); })
        }
      })
    }
  }
}
</script>
